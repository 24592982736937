import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { UserResolverService } from './core/services/auth/user.resolver.service';
import { AgreementsResolverService } from './core/services/auth/agreements.resolver.service';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        resolve: {
          userData: UserResolverService,
          agreements: AgreementsResolverService,
        },
        loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'wizard',
        loadChildren: () => import('./modules/wizard/wizard.module').then((m) => m.WizardModule),
      },
      {
        path: 'error',
        loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
      },
      {
        path: 'invite/accept',
        loadChildren: () => import('./modules/invite/invite.module').then((m) => m.InviteModule),
      },
    ],
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
