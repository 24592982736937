import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AuthServiceInterface, IAgreement, IAgreementsResponse } from './auth-service.interface';
import { LocalStorageServiceInterface } from '../local-storage/local-storage-service.interface';
import { ConfigServiceInterface } from '../config/config-service.interface';
import { UserResponse } from './responses/user.response';
import { SignDetailsRequest } from './requests/sign-details.request';
import { AuthResponse } from './responses/auth.response';
import { redirectAfterLogin } from '../../helpers/redirect-after-login.function';

@Injectable()
export class AuthService implements AuthServiceInterface {
  private agreements: IAgreement[] = [];
  private user: UserResponse;

  constructor(
    private http: HttpClient,
    @Inject('ConfigServiceInterface') private configService: ConfigServiceInterface,
    @Inject('LocalStorageServiceInterface') private localStorageService: LocalStorageServiceInterface
  ) {
    // this.eventsService.on('jwtAuthIframeUrls:loaded').subscribe(() => {
    //   redirectAfterLogin({
    //     token: this.localStorageService.getItem('token'),
    //   });
    // });
  }

  redirectAfterLogin(): void {
    redirectAfterLogin({
      token: this.localStorageService.getItem('token'),
    });
  }

  isAuthorized(): boolean {
    return !!this.localStorageService.getItem('token');
  }

  getUser(): Observable<UserResponse> {
    return this.http.get<any>(`${this.configService.apiUrl}/user`).pipe(
      map((data) => {
        this.setUser(
          UserResponse.fromJson({
            data: data.data,
          })
        );
        return UserResponse.fromJson(data);
      })
    );
  }

  getAgreementsStatus(): Observable<IAgreement[]> {
    return this.http.get<IAgreementsResponse>(`${this.configService.apiUrl}/agreements`).pipe(
      map((response) => {
        this.agreements = response.data
        return response.data;
      }),
      share(),
    );
  }

  getAgreements(): IAgreement[] {
    return this.agreements;
  }

  getUserData(): UserResponse {
    return this.user;
  }

  setUser(user: UserResponse): void {
    this.user = user;
  }

  auth(signDetailsRequest: SignDetailsRequest, successCb: (response: AuthResponse) => void, errorCb: (error: any) => void): void {
    this.http
      .post<AuthResponse>(`${this.configService.apiUrl}/login`, signDetailsRequest)
      .pipe(
        map((data) =>
          Object.assign(new AuthResponse(), {
            data: {
              token: data.data.access_token,
              user: data.data.user,
            },
          })
        )
      )
      .subscribe(
        (response) => {
          this.localStorageService.setItem('token', response.data.token);
          this.setUser(
            UserResponse.fromJson({
              data: response.data.user,
            })
          );
          successCb(response);
        },
        (error) => {
          errorCb(error);
        }
      );
  }

  logout(): void {
    this.http.get(`${this.configService.apiUrl}/logout`).subscribe(() => {
      this.localStorageService.removeItem('token');
      window.location.reload();
      // this.eventsService.broadcast('authService:logout', {
      //   callback: () => {
      //     window.location.reload();
      //   },
      // });
    });
  }
}
