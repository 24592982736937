import { Inject, Injectable } from '@angular/core';
import { AuthServiceInterface, IAgreement } from './auth-service.interface';
import { Observable } from 'rxjs';

@Injectable()
export class AgreementsResolverService  {
  constructor(
    @Inject('AuthServiceInterface') private authService: AuthServiceInterface
  ) {}

  resolve(): Observable<IAgreement[]> {
    return this.authService.getAgreementsStatus();
  }
}
